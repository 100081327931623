export class TimeUtil {

  // Gibt Anzahl Stunden als Kommazahl zurück
  static totalHours(start: Date, end: Date) {
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    return diffMinutes / 60;
  }

  // Berechnet die Anzahl Tage zwischen 2 Datum
  static daysBetween(startDate: Date, endDate: Date) {
    if (!startDate || !endDate) { return null; }
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const timeDiff = Math.abs(startDate.getTime() - endDate.getTime());
    const diffDays = Math.ceil(timeDiff / millisecondsPerDay);
    return diffDays;
  }

  // Fügt Anzahl Tage einem Datum hinzu
  static addDays(date: Date, days: number) {
    const newDate = new Date(date.valueOf());
    newDate.setDate(date.getDate() + days);
    return newDate;
  }

  // Fügt Anzahl Stunden einem Datum hinzu
  static addHours(date: Date, hours: number) {
    const newDate = new Date(date.valueOf());
    newDate.setHours(date.getHours() + hours);
    return newDate;
  }

  // Fügt Anzahl Minuten einem Datum hinzu
  static addMinutes(date: Date, minutes: number) {
    const millisecondsPerMinute = 60 * 1000;
    return new Date(date.getTime() + minutes * millisecondsPerMinute);
  }

  // Zieht Anzahl Minuten einem Datum hinzu
  static subMinutes(date: Date, minutes: number) {
    const millisecondsPerMinute = 60 * 1000;
    return new Date(date.getTime() - minutes * millisecondsPerMinute);
  }

  // Gibt Datum mit leerer Zeit zurück
  static getDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  // Vergleicht 2 Daten ohne Zeit
  static compareDate(date1: Date, date2: Date) {
    return this.getDate(date1).getTime() === this.getDate(date2).getTime();
  }

  static setTime(date: Date, hours: number, min?: number, sec?: number, ms?: number) {
    return new Date(date.setHours(
      hours,
      min === undefined ? null : min,
      sec === undefined ? null : sec,
      ms === undefined ? null : ms),
    );
  }

  static isBetween(value: Date, from: Date, to: Date) {
    return (value > from && value < to);
  }



}
