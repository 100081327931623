import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GmapService {

  constructor() { }

  getUrl(address) {
    const line1 = encodeURI(address.line1);
    const city = encodeURI(address.city);

    const destination = `${line1},+${address.postalCode}+${city}`;
    // https://developers.google.com/maps/documentation/urls/guide#directions-action
    return `https://www.google.ch/maps/dir/?api=1&destination=${destination}`;
  }
}
