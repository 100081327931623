import { Injectable, inject } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

import { AuthService } from './auth.service';
import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private authService = inject(AuthService);
  private oAuthModuleConfig = inject(OAuthModuleConfig);


  public apiUrl$: ReplaySubject<string>;

  constructor() {
    this.apiUrl$ = new ReplaySubject<string>(1);

    this.authService.claims$.pipe(
      filter(claims => claims),
    ).subscribe(claims => {
      const apiUrl = environment.production
        ? claims.apiUrl
        : environment.apiUrl;

      // Send AccessToken in Bearer Token to api
      this.oAuthModuleConfig.resourceServer.allowedUrls = [apiUrl];

      // console.log(this.oAuthModuleConfig.resourceServer.allowedUrls);
      this.apiUrl$.next(apiUrl);
    });

  }

}
