import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { filter, switchMap, tap } from 'rxjs/operators';

import { AuthService } from '../auth.service';

export const isLoggedInGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);

  return authService.isDoneLoading$
    .pipe(
      filter(isDone => isDone),
      switchMap(() => authService.isAuthenticated$),
      // tap((isAuthenticated) => console.log('loggedin guard: ', isAuthenticated)),
      tap(isAuthenticated => isAuthenticated || authService.login(state.url)), // Force Loging wenn nicht
      // map(() => this.isAuthenticated),
    );
};

export const isAuthorizedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService = inject(AuthService);

  return authService.canActivateProtectedRoutes$
    .pipe(
      tap((isAuthorized) => console.log('isAuthorized: ', isAuthorized)),
    );
};
