import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import lifecycle from 'page-lifecycle';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  private resumeSubject$ = new Subject<any>();
  public resumed$ = this.resumeSubject$.asObservable();

  constructor() {

    lifecycle.addEventListener('statechange', (event) => {
      // use active state for unfrozen from mobile browsers
      if (event.newState === 'active') {
        this.resumeSubject$.next(true);
      }
    });

    window.addEventListener('online', () => {
      this.resumeSubject$.next(true);
    });

  }

}
