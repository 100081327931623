import { Injectable, inject } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage = inject(Storage);
  private _initPromise: Promise<Storage> | null = null;

  // Inoic-Storage als einfacher Wrapper für IndexedDB
  constructor() {
    this.init();
  }

  async init() {
    if (!this._initPromise) {
      this._initPromise = this.storage.create().then(storage => {
        // console.log('StorageService: initialized');
        return storage;
      });
    }
    return this._initPromise;
  }

  public async set(key: string, value: any) {
    const storage = await this.init();
    return storage.set(key, value);
  }

  public async get(key: string) {
    const storage = await this.init();
    return storage.get(key);
  }

}